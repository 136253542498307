<template>
  <div class="article">
    <div class="contents">
      <button class="btn_sub1 list_load" @click="showSalesSearch = true">
        수주항목 불러오기
      </button>
      <div class="order_info">
        <h6>작업지시 정보</h6>
        <div>
          <div>
            <label>작업지시일</label>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                id="input_date"
                :value="newData.input_date"
                @change="setInputDate($event)"
                v-if="showSearchDate"
              />
            </div>
          </div>
          <div>
            <label>작업 완료예정일</label>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                id="deadline_date"
                :value="newData.deadline_date"
                @change="setDeadLineDate($event)"
                v-if="showSearchDate"
              />
            </div>
          </div>
          <div>
            <label>생산제품</label>
            <div class="input_search">
              <input
                type="text"
                placeholder="제품명 검색"
                :value="
                  newData.product_id == null
                    ? product_search_text
                    : findInfoFromId(products, newData.product_id).name
                "
                @input="handleProductInput($event)"
                :readonly="newData.product_id != null"
              />
              <button type="button" @click="showProductSearch = true">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <button
            @click="goBomManagementPage(newData.product_id)"
            v-if="newData.product_id != null"
            class="btn_sub2"
          >
            BOM
          </button>
        </div>
        <div>
          <div>
            <label>납품처</label>
            <div class="input_search input_search2">
              <input
                type="text"
                :value="
                  newData.company_id == null
                    ? '자체생산'
                    : findInfoFromId(companys, newData.company_id).name
                "
                readonly
              />
              <button type="button" class="btn_del">
                <i
                  class="fa fa-times"
                  @click="clearCompanyId"
                  v-if="newData.company_id != null"
                ></i>
              </button>
              <button type="button">
                <i class="fa fa-search" @click="showCompanySearch = true"></i>
              </button>
            </div>
          </div>
          <div>
            <label
              >작업수량({{
                newData.product_id == undefined
                  ? '단위'
                  : findInfoFromId(
                      units,
                      findInfoFromId(products, newData.product_id)
                        .stock_unit_id,
                    ).name
              }})</label
            >
            <div class="input_text">
              <input
                class="text_right"
                :readonly="newData.product_id == null"
                type="text"
                placeholder="0"
                inputmode="decimal"
                :value="$makeComma(newData.quantity)"
                @input="
                  $inputNumber($event, newData, 'quantity');
                  $store.commit('setNewGroupMaterialToWorkOrder', []);
                "
              />
            </div>
          </div>
          <div class="passive_check">
            <label>자재 수동투입</label>
            <div class="input_checkbox">
              <label
                for="checkbox2"
                class="chk_box"
                :class="{
                  active: __isFixResource,
                }"
              >
                <i class="fa fa-check"></i>
              </label>
              <input type="checkbox" id="checkbox2" @click="toggleCheckBox()" />
            </div>
            <small class="tooltip"
              >소요 원자재가 시스템상 작업시작시 <br />
              전량 소진되어 진행됩니다.</small
            >
          </div>
          <div>
            <label>긴급 작업지시</label>
            <div class="input_checkbox">
              <label
                for="checkbox529"
                class="chk_box"
                :class="newData.emergency_yn == true ? 'active' : ''"
              >
                <i class="fa fa-check"></i>
              </label>
              <input
                type="checkbox"
                id="checkbox529"
                @click="newData.emergency_yn = !newData.emergency_yn"
              />
            </div>
          </div>
        </div>
        <div
          class="package_input"
          v-if="findInfoFromId(products, newData.product_id).product_group_yn"
        >
          <div class="input_text">
            <label>포장 지시</label>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>제품명</th>
                    <th>규격</th>
                    <th>포장 수량</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in newData.lot_detail"
                    :key="item.id"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="text_left">
                      {{
                        findInfoFromId(products, item.member_product_id).name
                      }}
                    </td>
                    <td class="text_left">
                      {{
                        findInfoFromId(products, item.member_product_id)
                          .standard
                      }}
                    </td>
                    <td class="input_td">
                      <input
                        type="text"
                        inputmode="decimal"
                        :value="$makeComma(item.quantity)"
                        @input="$inputNumber($event, item, 'quantity')"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>작업지시사항</label>
            <textarea
              placeholder="내용을 입력하세요"
              v-model="newData.detail"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="list_wrap">
        <h5>투입 항목</h5>

        <div>
          <h6>투입 원자재</h6>
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <colgroup :class="{ fix_resource: __isFixResource }">
                <col
                  v-for="(n, index) in __isFixResource ? 5 : 6"
                  :key="index"
                />
              </colgroup>
              <thead>
                <tr>
                  <th>원자재명</th>
                  <th>규격</th>
                  <th v-show="!__isFixResource">개별 소요량</th>
                  <th>작업 소요량</th>
                  <th>단위</th>
                  <th>작업 후 예상 재고</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(mat, index) in __isFixResource
                    ? __fixExpectMaterial
                    : group_material_list"
                  :key="mat.id"
                >
                  <td class="text_left">
                    {{ findInfoFromId(materials, mat.material_id).name }}
                    <button
                      class="float_search"
                      v-if="
                        findInfoFromId(materials, mat.material_id)
                          .material_group_yn
                      "
                      @click="
                        target_group_id = mat.material_id;
                        target_need_quantity = calNeedQuantity(mat, 1);
                        showGroupSelect = true;
                      "
                    >
                      <img
                        src="@/assets/images/icon/icon-floating-search.png"
                      />
                    </button>
                  </td>
                  <td class="text_left">
                    {{ findInfoFromId(materials, mat.material_id).standard }}
                  </td>
                  <td class="text_right" v-show="!__isFixResource">
                    {{
                      mat.group_material_id == null
                        ? $makeComma($makeNumber(mat.quantity)) +
                          (mat.percent_yn ? '%' : '')
                        : ''
                    }}
                  </td>
                  <td class="text_right" v-if="!__isFixResource">
                    {{ $makeComma(calNeedQuantity(mat, 1)) }}
                  </td>
                  <td v-else class="input_td">
                    <input
                      type="text"
                      inputmode="decimal"
                      :value="$makeComma(mat.work_out_quantity)"
                      @input="$inputNumber($event, mat, 'work_out_quantity')"
                    />
                  </td>
                  <td>
                    {{
                      findInfoFromId(
                        units,
                        findInfoFromId(materials, mat.material_id)
                          .using_unit_id,
                      ).name
                    }}
                  </td>
                  <td class="text_right">
                    <b>{{ $makeComma(getMaterialCalQuantity(index)) }}</b>
                    <span>{{
                      `(${
                        findInfoFromId(
                          units,
                          findInfoFromId(materials, mat.material_id)
                            .incoming_unit_id,
                        ).name
                      })`
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <h6>투입 반제품&제품</h6>
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <colgroup :class="{ fix_resource: __isFixResource }">
                <col
                  v-for="(n, index) in __isFixResource ? 5 : 6"
                  :key="index"
                />
              </colgroup>
              <thead>
                <tr>
                  <th>제품명</th>
                  <th>규격</th>
                  <th v-show="!__isFixResource">개별 소요량</th>
                  <th>작업 소요량</th>
                  <th>단위</th>
                  <th>작업 후 예상 재고</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(pro, index) in __isFixResource
                    ? __fixExpectProduct
                    : group_product_list"
                  :key="pro.id"
                >
                  <td class="text_left">
                    {{ findInfoFromId(products, pro.product_id).name }}
                  </td>
                  <td class="text_left">
                    {{ findInfoFromId(products, pro.product_id).standard }}
                  </td>
                  <td class="text_right" v-show="!__isFixResource">
                    {{ $makeComma($makeNumber(pro.quantity)) }}
                  </td>
                  <td class="text_right" v-if="!__isFixResource">
                    {{ $makeComma(calNeedQuantity(pro, 2)) }}
                  </td>
                  <td v-else class="input_td">
                    <input
                      type="text"
                      inputmode="decimal"
                      :value="$makeComma(pro.work_out_quantity)"
                      @input="$inputNumber($event, pro, 'work_out_quantity')"
                    />
                  </td>
                  <td>
                    {{
                      findInfoFromId(
                        units,
                        findInfoFromId(products, pro.product_id).stock_unit_id,
                      ).name
                    }}
                  </td>
                  <td class="text_right">
                    {{ mx_makeComma(getProductCalQuantity(index)) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="btn_wrap">
          <button
            v-if="$makeNumber(newData.quantity) > 0"
            class="btn_sub2"
            @click="submitForm()"
          >
            작업 등록
          </button>
        </div>
      </div>
    </div>
    <div
      class="cont_navi slide_btn_wrap"
      v-if="selected_product_list.length > 1"
    >
      <div
        class="left_cont"
        v-if="selected_product_list_info.selected_index > 0"
      >
        <button
          type="button"
          @click="switchProduct(selected_product_list_info.selected_index - 1)"
        >
          <i class="fa fa-angle-left"></i>
        </button>
        <span>{{
          findInfoFromId(
            products,
            selected_product_list[selected_product_list_info.selected_index - 1]
              .product_id,
          ).name
        }}</span>
      </div>
      <div
        class="right_cont"
        v-if="
          selected_product_list_info.selected_index <
            selected_product_list_info.length - 1
        "
      >
        <span>{{
          findInfoFromId(
            products,
            selected_product_list[selected_product_list_info.selected_index + 1]
              .product_id,
          ).name
        }}</span>
        <button
          type="button"
          @click="switchProduct(selected_product_list_info.selected_index + 1)"
        >
          <i class="fa fa-angle-right"></i>
        </button>
      </div>
    </div>
    <resource-search
      v-if="showProductSearch"
      :filter_type="2"
      :groupType="1"
      :pre_search_value="product_search_text"
      @onclose="showProductSearch = false"
      @onselect="selectProduct($event)"
    ></resource-search>
    <sales-search
      v-if="showSalesSearch"
      :search_type="2"
      @onclose="showSalesSearch = false"
      @onselect="pushProductsToList($event)"
    ></sales-search>
    <company-search
      v-if="showCompanySearch"
      :search_type="1"
      @onclose="showCompanySearch = false"
      @onselect="pushManagementCompany($event)"
    ></company-search>
    <resource-group-select
      v-if="showGroupSelect"
      :group_resource="target_group_id"
      :goal_value="target_need_quantity"
      @onclose="showGroupSelect = false"
      @onselect="
        $event => {
          setGroupMaterial($event);
          showGroupSelect = false;
        }
      "
    >
    </resource-group-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import CompanySearch from '@/layouts/components/search-popup/CompanySearch';
import SalesSearch from '@/layouts/components/search-popup/SalesSearch';
import ResourceGroupSelect from '@/layouts/components/search-popup/ResourceGroupSelect';

// import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { yyyymmdd } from '@/utils/func';
import PriceMixin from '@/mixins/material_price.js';
import routes from '@/routes/routes';

export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin, PriceMixin],
  components: {
    ResourceSearch,
    SalesSearch,
    CompanySearch,
    ResourceGroupSelect,

    // TwoButtonModal,
  },
  data() {
    return {
      //resource search modal parameter
      showProductSearch: false,
      product_search_text: '',

      //local flag
      showSearchDate: false,

      //search popup
      showSalesSearch: false,
      showCompanySearch: false,

      //group_select
      target_group_id: null,
      target_need_quantity: null,
      showGroupSelect: false,

      bom_parents_id_count: -1,
      checkHover: false,
    };
  },
  computed: {
    ...mapGetters({
      lot_types: 'getLotType',
      __isFixResource: 'getNewFixResourceFromWorkOrder',
      __fixExpectMaterial: 'getNewFixExpectMaterial',
      __fixExpectProduct: 'getNewFixExpectProduct',
      products: 'getProduct',
      product_groups: 'getProductGroup',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      unit_conversion: 'getUnitConversion',
      sales: 'getSales',
      newData: 'getNewDataFromWorkOrder',
      seller_companys: 'getSellerCompany',
      companys: 'getCompany',
      boms: 'getBom',
      process: 'getProcess',
      material_stock_list: 'getMaterialTotalStock',
      product_stock_list: 'getProductTotalStock',
      material_occupied_stock: 'getMaterialOccupiedStock',
      product_occupied_stock: 'getProductOccupiedStock',
      selected_product_list: 'getNewSelectedSalesProducts',
      boxs: 'getBoxs',
      lots: 'getLot',
      newMaterialGroup: 'getNewGroupMaterialFromWorkOrder',
    }),
    selected_product_list_info() {
      let indexTemp = -1;
      this.selected_product_list.forEach((el, index) => {
        if (el.product_id == this.newData.product_id) {
          indexTemp = index;
        }
      });
      return {
        length: this.selected_product_list.length,
        selected_index: indexTemp,
      };
    },
    group_material_list() {
      if (this.newData.bom_id == null) return [];

      const { bom_process } = this.findInfoFromId(
        this.boms,
        this.newData.bom_id,
      );
      let group_list = [];
      bom_process.forEach(process => {
        const filter_list = this.lodash.clonedeep(
          process.bom_resource.filter(x => x.material_id != null),
        );
        filter_list.forEach(element => {
          let already_exist = group_list.find(
            x => x.material_id == element.material_id,
          );
          if (already_exist != undefined) {
            already_exist.quantity = this.$decimalAdd(
              already_exist.quantity,
              element.quantity,
            );
          } else {
            group_list.push(element);
          }
        });
      });
      if (this.newMaterialGroup.length > 0) {
        const mapGroupMaterial = this.newMaterialGroup.map(
          x => x.group_material_id,
        );
        group_list = group_list.filter(
          x => !mapGroupMaterial.includes(x.material_id),
        );

        return group_list.concat(this.newMaterialGroup);
      }
      return group_list;
    },
    group_product_list() {
      if (this.newData.bom_id == null) return [];
      const { bom_process } = this.findInfoFromId(
        this.boms,
        this.newData.bom_id,
      );
      let group_list = [];
      bom_process.forEach(process => {
        const filter_list = this.lodash.clonedeep(
          process.bom_resource.filter(x => x.product_id != null),
        );
        filter_list.forEach(element => {
          let already_exist = group_list.find(
            x => x.product_id == element.product_id,
          );
          if (already_exist != undefined) {
            already_exist.quantity = this.$decimalAdd(
              already_exist.quantity,
              this.$makeNumber(element.quantity),
            );
          } else {
            group_list.push(element);
          }
        });
      });
      return group_list;
    },
    checkValid() {
      let cnt = 0;
      this.group_material_list.forEach((el, index) => {
        if (this.getMaterialCalQuantity(index) < 0) {
          cnt++;
        }
      });
      if (cnt < 1) {
        this.group_product_list.forEach((el, index) => {
          if (this.getProductCalQuantity(index) < 0) {
            cnt++;
          }
        });
        if (cnt < 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleCheckBox() {
      console.log('togglecheckbox');
      this.$store.commit('setNewFixResourceToWorkOrder', !this.__isFixResource);
    },
    setGroupMaterial(arg) {
      let listTemp = this.lodash.clonedeep(this.newMaterialGroup);
      listTemp = listTemp.filter(
        x => x.group_material_id != this.target_group_id,
      );

      arg.forEach((el, index) => {
        const groupM = this.lodash.clonedeep(
          this.group_material_list.find(
            x => x.material_id == this.target_group_id,
          ),
        );
        groupM.id += 1000 + index;
        groupM.material_id = el.id;
        groupM.name = el.name;
        groupM.quantity = this.$makeNumber(el.quantity);
        groupM.group_material_id = this.target_group_id;
        listTemp.push(groupM);
      });
      console.log(arg);
      this.$store.commit('setNewGroupMaterialToWorkOrder', listTemp);
    },
    calNeedQuantity(resource, resource_type) {
      if (resource.group_material_id != undefined) {
        return resource.quantity;
      }
      if (resource.percent_yn) {
        let resourceInfo = null;
        if (resource_type == 1) {
          resourceInfo = this.findInfoFromId(
            this.materials,
            resource.material_id,
          );
        } else {
          resourceInfo = this.findInfoFromId(
            this.products,
            resource.product_id,
          );
        }

        const productInfo = this.findInfoFromId(
          this.products,
          this.newData.product_id,
        );
        let cvr_ratio = null;
        if (resource_type == 1) {
          cvr_ratio = this.unit_conversion.find(
            x =>
              x.in_unit_id == resourceInfo.using_unit_id &&
              x.out_unit_id == productInfo.stock_unit_id,
          );
        } else {
          cvr_ratio = this.unit_conversion.find(
            x =>
              x.in_unit_id == resourceInfo.stock_unit_id &&
              x.out_unit_id == productInfo.stock_unit_id,
          );
        }

        if (cvr_ratio != undefined) {
          return this.$decimalMul(
            this.$decimalMul(
              this.$makeNumber(this.newData.quantity),
              this.$makeNumber(this.$decimalDiv(resource.quantity, 100)),
            ),
            cvr_ratio.cvr_ratio,
          );
        } else {
          return this.$decimalMul(
            this.$makeNumber(this.newData.quantity),
            this.$decimalDiv(resource.quantity, 100),
          );
        }
      } else {
        return this.$makeNumber(
          this.$decimalMul(
            this.$makeNumber(resource.quantity),
            this.$makeNumber(this.newData.quantity),
          ),
        );
      }
    },
    clearCompanyId() {
      this.newData.company_id = null;
      this.$store.commit('setNewSelectedSalesProducts', []);
      this.newData.sales_id = null;
    },

    getMaterialCalQuantity(index) {
      const stock_quantity = this.findInfoFromId(
        this.material_stock_list,
        this.group_material_list[index].material_id,
      ).stock_quantity;
      const occupied_stock = this.findInfoFromId(
        this.material_occupied_stock,
        this.group_material_list[index].material_id,
      ).quantity;

      const calStock = this.$decimalSub(
        stock_quantity != undefined ? stock_quantity : 0,
        occupied_stock != undefined ? occupied_stock : 0,
      );
      if (this.__isFixResource) {
        return this.$makeNumber(
          this.$decimalSub(
            calStock,
            this.$decimalMul(
              this.$makeNumber(
                this.__fixExpectMaterial[index].work_out_quantity,
              ),
              this.getUnitConversion(
                this.findInfoFromId(
                  this.materials,
                  this.__fixExpectMaterial[index].material_id,
                ).incoming_unit_id,
                this.findInfoFromId(
                  this.materials,
                  this.__fixExpectMaterial[index].material_id,
                ).using_unit_id,
              ),
            ),
          ),
        );
      } else {
        return this.$makeNumber(
          this.$decimalSub(
            calStock,
            this.$decimalMul(
              this.calNeedQuantity(this.group_material_list[index], 1),
              this.getUnitConversion(
                this.findInfoFromId(
                  this.materials,
                  this.group_material_list[index].material_id,
                ).incoming_unit_id,
                this.findInfoFromId(
                  this.materials,
                  this.group_material_list[index].material_id,
                ).using_unit_id,
              ),
            ),
          ),
        );
      }
    },
    getProductCalQuantity(index) {
      const stock_quantity = this.findInfoFromId(
        this.product_stock_list,
        this.group_product_list[index].product_id,
      ).stock_quantity;

      const occupied_stock = this.findInfoFromId(
        this.product_occupied_stock,
        this.group_product_list[index].product_id,
      ).quantity;

      const calStock = this.$decimalSub(
        stock_quantity != undefined ? stock_quantity : 0,
        occupied_stock != undefined ? occupied_stock : 0,
      );
      if (this.__isFixResource) {
        return this.$makeNumber(
          this.$decimalSub(
            calStock,
            this.$makeNumber(this.__fixExpectProduct[index].work_out_quantity),
          ),
        );
      } else {
        return this.$makeNumber(
          this.$decimalSub(
            calStock,
            this.calNeedQuantity(this.group_product_list[index], 2),
          ),
        );
      }
    },
    async submitForm() {
      if (!this.checkValid) {
        this.openOneButtonModal(
          '재고부족',
          '작업을 위한 재고가 부족하여<br />작업지시를 내릴 수 없습니다.',
        );
      } else {
        const payload = this.lodash.clonedeep(this.newData);
        if (payload.lot_detail.length > 0) {
          payload.lot_detail = payload.lot_detail.map(x => {
            x.quantity = this.$makeNumber(x.quantity);
            return x;
          });
        }
        payload.quantity = this.$makeNumber(payload.quantity);
        payload.lot_type_id = this.lot_types.find(
          x => x.detail == 'wait process',
        ).id;
        if (this.__isFixResource) {
          payload.custom_input_yn = true;
        }

        const mat_group = this.__isFixResource
          ? this.__fixExpectMaterial
          : this.lodash.clonedeep(this.group_material_list);
        const pro_group = this.__isFixResource
          ? this.__fixExpectProduct
          : this.lodash.clonedeep(this.group_product_list);

        let resource_expect = [];

        // return this.$makeNumber(
        //   this.$decimalMul(
        //     this.$makeNumber(resource.quantity),
        //     this.$makeNumber(this.newData.quantity),
        //   ),
        // );
        if (this.__isFixResource) {
          mat_group.forEach(el => {
            resource_expect.push({
              material_id: el.material_id,
              product_id: null,
              quantity: this.$makeNumber(
                this.$decimalMul(
                  this.$makeNumber(el.work_out_quantity),
                  this.getUnitConversion(
                    this.findInfoFromId(this.materials, el.material_id)
                      .incoming_unit_id,
                    this.findInfoFromId(this.materials, el.material_id)
                      .using_unit_id,
                  ),
                ),
              ),
            });
          });
          pro_group.forEach(el => {
            resource_expect.push({
              material_id: null,
              product_id: el.product_id,
              quantity: this.$makeNumber(el.work_out_quantity),
            });
          });
        } else {
          mat_group.forEach(el => {
            resource_expect.push({
              material_id: el.material_id,
              product_id: null,
              quantity: this.$makeNumber(
                this.$decimalMul(
                  this.calNeedQuantity(el, 1),
                  this.getUnitConversion(
                    this.findInfoFromId(this.materials, el.material_id)
                      .incoming_unit_id,
                    this.findInfoFromId(this.materials, el.material_id)
                      .using_unit_id,
                  ),
                ),
              ),
            });
          });
          pro_group.forEach(el => {
            resource_expect.push({
              material_id: null,
              product_id: el.product_id,
              quantity: this.$makeNumber(this.calNeedQuantity(el, 2)),
            });
          });
        }

        payload.lot_resource_expect_list = resource_expect;

        console.log(payload);

        this.showSpinner();
        this.$store
          .dispatch('INSERT_LOT', payload)
          .then(async response => {
            console.log(response);
            if (payload.emergency_yn == true) {
              this.isEmergencyModalOpen = true;
            }

            this.openOneButtonModal(
              '등록 성공',
              '작업지시서를 성공적으로 등록하였습니다.',
            );
            this.FETCH('FETCH_LOT_ALL_FROM_WORKORDER', '작업지시');
            this.$store.commit('setNewFixResourceToWorkOrder', false);
            this.$store.commit('setNewFixExpectMaterial', []);
            this.$store.commit('setNewFixExpectProduct', []);
            const selIndex = this.lodash.clonedeep(
              this.selected_product_list_info.selected_index,
            );
            const length = this.lodash.clonedeep(
              this.selected_product_list_info.length,
            );
            if (selIndex == -1) {
              await this.resetForm();
            } else if (selIndex < length - 1) {
              this.switchProduct(selIndex + 1);
              this.selected_product_list.splice(selIndex, 1);
            } else {
              if (selIndex == 0) {
                await this.resetForm();
              } else {
                this.switchProduct(selIndex - 1);
                this.selected_product_list.splice(selIndex, 1);
                // this.$store.commit(
                //   'setNewSelectedSalesProducts',
                //   this.lodash.clonedeep(
                //     this.selected_product_list.splice(selIndex, 1),
                //   ),
                // );
              }
            }
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal(
              '등록 오류',
              '작업지시서 등록 중 오류가 발생했습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async resetForm() {
      // this.$store.commit('setNewFix');
      await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '자재 현재고');
      await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '제품 현재고');
      await this.FETCH('FETCH_MATERIAL_OCCUPIED_STOCK', '자재 사용중 재고');
      await this.FETCH('FETCH_PRODUCT_OCCUPIED_STOCK', '제품 사용중 재고');
      await this.FETCH('FETCH_LOT_ALL', '작업지시');

      this.$store.commit('setNewDataToWorkOrder', {
        input_date: null,
        deadline_date: null,
        product_id: null,
        bom_id: null,
        quantity: 0,
        sales_id: null,
        company_id: null,
        detail: '',
        emergency_yn: false,
      });
      var date = new Date();
      this.newData.input_date = yyyymmdd(date);
      var date2 = new Date(date.setDate(date.getDate() + 7));
      this.newData.deadline_date = yyyymmdd(date2);
      this.$store.commit('setNewSelectedSalesProducts', []);
    },
    switchProduct(index) {
      console.log('func switch product');
      this.newData.quantity = this.selected_product_list[index].total_quantity;
      this.newData.sales_id = this.selected_product_list[index].sales_id;
      this.selectProduct({ id: this.selected_product_list[index].product_id });
    },
    pushManagementCompany(arg) {
      if (this.showCompanySearch) {
        this.showCompanySearch = false;
        this.$store.commit('setNewSelectedSalesProducts', []);
        this.newData.sales_id = null;
      }
      this.newData.company_id = arg.id;
    },
    pushProductsToList(arg) {
      this.$store.commit('setNewSelectedSalesProducts', []);

      arg.product_list = arg.product_list.filter(
        x =>
          this.findInfoFromId(this.products, x.product_id)
            .product_group_type_id != 2,
      );
      console.log('arg.product_list', arg.product_list);
      arg.product_list.forEach(el => {
        //여기서 이미 등록되어있는지 아닌지 필터링
        el.bom_parents_id = el.id;
        el.total_quantity =
          el.box_unit_id != null
            ? this.$decimalMul(
                this.findInfoFromId(this.boxs, el.box_unit_id).quantity,
                el.quantity,
              )
            : el.quantity;
        this.selected_product_list.push(el);
        this.makeBomProduct(el);
      });

      const temp = this.lodash
        .clonedeep(this.selected_product_list)
        .map(x => {
          if (this.boms.find(y => y.product_id == x.product_id) == undefined) {
            return {
              sales_product_id: x.product_id,
              quantity: this.$decimalMul(
                x.total_quantity,
                this.product_groups.find(
                  y => y.member_product_id == x.product_id,
                ) != undefined
                  ? this.product_groups.find(
                      y => y.member_product_id == x.product_id,
                    ).quantity
                  : 1,
              ),
              group_product_id:
                this.product_groups.find(
                  y => y.member_product_id == x.product_id,
                ) != undefined
                  ? this.product_groups.find(
                      y => y.member_product_id == x.product_id,
                    ).group_product_id
                  : null,
            };
          }
        })
        .filter(x => x != undefined);

      const obj = this.lodash.clonedeep(
        temp.reduce(function(rv, x) {
          (rv[x['group_product_id']] = rv[x['group_product_id']] || []).push(x);
          return rv;
        }, {}),
      );

      let arr = [];

      for (let k in obj) {
        if (k != 'null') {
          arr.push({
            group_product_id: k,
            quantity: obj[k]
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0),
            sales_product_lists: obj[k].map(x => x.sales_product_id),
          });
        }
      }
      console.log('arr', arr);

      arr.forEach(el => {
        const selectFromProductList = this.lodash.clonedeep(
          this.selected_product_list.find(
            x => x.product_id == el.sales_product_lists[0],
          ),
        );
        selectFromProductList.total_quantity = el.quantity;
        selectFromProductList.product_id = el.group_product_id;

        this.$store.commit(
          'setNewSelectedSalesProducts',
          this.selected_product_list.filter(
            x => !el.sales_product_lists.includes(x.product_id),
          ),
        );
        this.selected_product_list.push(selectFromProductList);
      });

      this.showSalesSearch = false;
      let workorder_product_list = [];
      this.selected_product_list.forEach(element => {
        const search_el = workorder_product_list.find(
          x => x.product_id == element.product_id,
        );
        if (search_el != undefined) {
          search_el.total_quantity = this.$decimalAdd(
            search_el.total_quantity,
            element.total_quantity,
          );
        } else {
          let hit = this.lots.find(
            x =>
              x.sales_id == element.sales_id &&
              x.product_id == element.product_id,
          );
          if (hit == undefined) {
            workorder_product_list.push({
              sales_id: this.lodash.clonedeep(element.sales_id),
              product_id: this.lodash.clonedeep(element.product_id),
              total_quantity: this.lodash.clonedeep(element.total_quantity),
              company_id: this.lodash.clonedeep(arg.company_id),
            });
          }
        }
      });
      // console.log('workorder_product_list ', workorder_product_list);
      if (workorder_product_list.length < 1) {
        this.openOneButtonModal(
          '작업지시 완료',
          '선택한 수주는 작업지시가 완료되었습니다.',
        );
        return;
      }
      // console.log(this.selected_product_list);
      this.$store.commit(
        'setNewSelectedSalesProducts',
        this.lodash.clonedeep(workorder_product_list),
      );

      this.newData.sales_id = this.selected_product_list[0].sales_id;
      this.newData.company_id = this.selected_product_list[0].company_id;
      this.newData.quantity = this.selected_product_list[0].total_quantity;
      this.selectProduct({ id: this.selected_product_list[0].product_id });
    },
    makeBomProduct(el) {
      //bom안에 제품이 또 포함되어 있다면 추가한다.
      const bom = this.boms.find(x => x.product_id == el.product_id);
      if (bom != undefined) {
        const el_unit_name = this.findInfoFromId(this.units, el.unit_id).name;
        const el_product_name = this.findInfoFromId(
          this.products,
          el.product_id,
        ).name;
        bom.bom_process.forEach(process => {
          process.bom_resource.forEach(product => {
            if (product.product_id != null) {
              const product_unit_name = this.findInfoFromId(
                this.units,
                this.findInfoFromId(this.products, product.product_id)
                  .stock_unit_id,
              ).name;
              const product_name = this.findInfoFromId(
                this.products,
                product.product_id,
              ).name;
              const new_element = {
                box_unit_id: null,
                id: this.bom_parents_id_count,
                product_id: product.product_id,
                quantity: product.quantity,
                total_quantity: this.$decimalMul(
                  product.quantity,
                  el.total_quantity,
                ),
                unit_id: this.findInfoFromId(this.products, product.product_id)
                  .stock_unit_id,
                sales_id: el.sales_id,
                product_label: el_product_name + ' >> ' + product_name,
                bom_parents_id: el.bom_parents_id,
                quantity_label: `${el.total_quantity}(${el_unit_name}) * ${
                  product.quantity
                }(${product_unit_name}) = ${this.$decimalMul(
                  product.quantity,
                  el.total_quantity,
                )}(${product_unit_name})`,
              };
              this.selected_product_list.push(new_element);
              this.bom_parents_id_count--;
              this.makeBomProduct(new_element);
            }
          });
        });
      }
    },
    goBomManagementPage(product_id) {
      const goingPage = routes[0].children.find(x => x.path == '/code/bom');
      if (goingPage != undefined) {
        goingPage.meta.select = product_id;
      }
      this.$router.push('/code/bom');
    },
    handleProductInput(e) {
      this.product_search_text = e.target.value;
    },
    async setInputDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.newData.deadline_date) {
          this.openOneButtonModal(
            '작업지시일 오류',
            `작업 완료예정일(${this.newData.deadline_date}) 이후의 날짜를<br>선택할 수 없습니다.`,
          );
          e.target.value = this.newData.input_date;
        } else {
          this.newData.input_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    async setDeadLineDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.newData.input_date) {
          this.openOneButtonModal(
            '작업 완료예정일 오류',
            `작업지시일(${this.newData.input_date}) 이전의 날짜를<br>선택할 수 없습니다.`,
          );
          e.target.value = this.newData.deadline_date;
        } else {
          this.newData.deadline_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    selectProduct(arg) {
      console.log('arg', arg);
      this.$store.commit('setNewFixResourceToWorkOrder', false);
      this.$store.commit('setNewFixExpectMaterial', []);
      this.$store.commit('setNewFixExpectProduct', []);
      this.$store.commit('setNewGroupMaterialToWorkOrder', []);
      this.newData.lot_detail = [];

      if (this.showProductSearch) {
        this.showProductSearch = false;
        this.$store.commit('setNewSelectedSalesProducts', []);
        this.newData.company_id = null;
        this.newData.quantity = 0;
        this.newData.sales_id = null;
      }
      console.log(
        'this.getBomFromProductId(arg.id)',
        this.getBomFromProductId(arg.id),
      );
      if (this.getBomFromProductId(arg.id) == null) {
        if (
          this.product_groups.filter(x => x.member_product_id == arg.id)
            .length > 0
        ) {
          arg = this.products.find(
            x =>
              x.id ==
              this.product_groups.find(x => x.member_product_id == arg.id)
                .group_product_id,
          );
        } else {
          this.openOneButtonModal(
            'BOM정보 없음',
            '선택한 제품의 BOM이 등록되어있지 않습니다.<br />BOM을 먼저 등록하여주십시오.',
          );
          this.goBomManagementPage(arg.id);
          return;
        }
      }
      this.newData.product_id = arg.id;
      this.newData.bom_id = this.getBomFromProductId(arg.id).id;
      if (this.findInfoFromId(this.products, arg.id).product_group_yn) {
        let findMemberProducts = this.lodash
          .clonedeep(this.product_groups)
          .filter(x => x.group_product_id == arg.id);
        if (findMemberProducts.length > 0) {
          findMemberProducts = findMemberProducts.map(x => {
            x.quantity = 0;
            return x;
          });
          this.newData.lot_detail = findMemberProducts;
        }
      }

      if (this.newData.company_id == null) {
        const seller_comp_list = this.getSellerCompanyFromProductId(arg.id);
        if (seller_comp_list.length > 0) {
          this.newData.company_id = seller_comp_list[0].company_id;
        }
      }
    },
    getSellerCompanyFromProductId(id) {
      const hit = this.seller_companys.filter(x => x.product_id == id);
      return hit;
    },
    async FETCH_SALES() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_SALES', {
          start_date: '1950-01-01',
          end_date: '2500-01-01',
          check_only_not_completed: true,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '수주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    getBomFromProductId(id) {
      if (id == undefined || id == null) {
        return null;
      }
      const hit = this.boms.find(x => x.product_id == id);
      return hit != undefined ? hit : null;
    },
    getUnitConversion(input, output) {
      if (input == output) return 1;
      const hit = this.unit_conversion.find(
        x => x.in_unit_id == input && x.out_unit_id == output,
      );
      return hit != undefined ? hit.cvr_ratio : 1;
    },
  },
  watch: {
    __isFixResource(newValue) {
      if (newValue) {
        const gml = this.lodash.clonedeep(this.group_material_list);
        gml.map(x => {
          x.work_out_quantity = this.calNeedQuantity(x, 1);
          return x;
        });
        this.$store.commit('setNewFixExpectMaterial', gml);

        const gpl = this.lodash.clonedeep(this.group_product_list);
        gpl.map(x => {
          x.work_out_quantity = this.calNeedQuantity(x, 2);
          return x;
        });
        this.$store.commit('setNewFixExpectProduct', gpl);
      } else {
        this.$store.commit('setNewFixExpectMaterial', []);
        this.$store.commit('setNewFixExpectProduct', []);
      }
    },
  },
  async created() {
    if (this.lot_types.length < 1) {
      await this.FETCH('FETCH_LOT_TYPE', '작업지시 상태');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.unit_conversion.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
    }
    if (this.seller_companys.length < 1) {
      await this.FETCH('FETCH_SELLER_COMPANY', '제품 매출처');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.boms.length < 1) {
      await this.FETCH('FETCH_BOM', 'BOM');
    }
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }

    await this.FETCH('FETCH_PRODUCT_GROUP', '제품 그룹');

    if (
      this.newData.product_id != undefined &&
      this.newData.product_id != null
    ) {
      this.selectProduct(
        this.findInfoFromId(this.products, this.newData.product_id),
      );
    }
    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '자재 현재고');
    await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '제품 현재고');
    await this.FETCH('FETCH_MATERIAL_OCCUPIED_STOCK', '자재 사용중 재고');
    await this.FETCH('FETCH_PRODUCT_OCCUPIED_STOCK', '제품 사용중 재고');
    await this.FETCH('FETCH_LOT_ALL', '작업지시');

    if (this.newData.input_date == null) {
      var date = new Date();
      this.newData.input_date = yyyymmdd(date);
      var date2 = new Date(date.setDate(date.getDate() + 7));
      this.newData.deadline_date = yyyymmdd(date2);
    }
    await this.FETCH_SALES();
    this.showSearchDate = true;
  },
};
</script>

<style lang="scss" scoped></style>
